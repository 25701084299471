@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400;700&display=swap");

@font-face {
  font-family: fontTitle;
  src: url("./assets/fonts/COMIC.TTF");
  font-weight: 400;
}

@font-face {
  font-family: fontTitle;
  src: url("./assets/fonts/font-bold.ttf");
  font-weight: bold;
}

a {
  cursor: pointer;
  color: inherit;
}

img {
  width: 100%;
}

body {
  background-color: #fff;
  color: #000;
  font-family: "Comic Neue", cursive;
}

.font-title {
  font-family: fontTitle, sans-serif;
}

.bg-footer {
  background: linear-gradient(180deg, #fed501 15.3%, #fea801 85.54%);
}

.App {
  overflow: hidden;
}
